import { useEffect, useState } from "react";
import Markdown from "react-markdown";

import styles from "./App.module.scss";
import DefaultInfo from "./DefaultInfo";

function App() {
  const [markdown, setMarkdown] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReadme = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://api.github.com/repos/islamashraful/islamashraful/readme",
          {
            headers: {
              Accept: "application/vnd.github.v3.raw",
            },
          }
        );
        if (response.ok) {
          let content = await response.text();
          // Remove HTML comments (e.g. start and end markdown comments)
          content = content.replace(/<!--[\s\S]*?-->/g, "");
          // Remove the `<p align="right">...</p>` line (e.g. last updated info)
          content = content.replace(
            /<p align="right"><code>[\s\S]*?<\/code><\/p>/g,
            ""
          );
          setMarkdown(content);
        } else {
          setError("Failed to fetch README");
          throw new Error("Failed to fetch README");
        }
      } catch (error) {
        setError(error.message);
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchReadme();
  }, []);

  return (
    <div className={styles.appContainer}>
      <div className={styles.leftContainer}>
        {loading && (
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        )}
        {error && <DefaultInfo />}
        {markdown && (
          <>
            <Markdown>{markdown}</Markdown>
            <code className={styles.tagline}>
              [This content has been generated using GithubAPI!🚀]
            </code>
          </>
        )}
      </div>
    </div>
  );
}

export default App;
